.container {
  display: flex;
  flex: 1;
  position: relative;
}

.room-container {
  display: flex;
  flex: 1;
}

.folderDialog{
  .submitBtn{
    background-color: #1976d2 !important;
  }
  .cancelBtn{
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .MuiTableRow-root{
    cursor: pointer;
  }
  .selectRow{
    background-color: #b5b5b5;
  }
  .MuiDialog-paperWidthSm{
    width: 600px;
  }
}
.folderInfo{
  height: 100px;
  align-self: center;
}

.classroom {
  .room-container {
    display: flex;
    flex: 1;

    .whiteboard {
      flex: 1;
    }

    .live-board {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-left: 1px solid #DBE2E5;
      max-width: 281px;
      box-sizing: border-box;
      height: 100%;

      .video-board {
        user-select: none;
        display: flex;
        flex-direction: column;
        //max-height: 318px;
        height: 159px;
        box-sizing: border-box;
      }

      .chat-board {
        background-color: #FFFFFF;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
    .large-live-board{
      max-width: 512px !important;

      .video-board{
        max-height: 100% !important;
      }
    }
  }
}

.teacherVideo{
  position: absolute;
  z-index: 999;
  right: 0;
  width: 50px;
  height: 80px;
}
.teacherLargeVideo{
  width: 512px;
  height: 300px;
}
.studentVideo{
  position: absolute;
  z-index: 999;
  right: 0;
  margin-top: 300px;
  width: 50px;
  height: 80px;
}
.studentVideo1{
  position: absolute;
  z-index: 999;
  right: 0;
  width: 50px;
  height: 80px;
}
.studentLargeVideo{
  width: 512px;
  height: 300px;
}